import CCEvents, {
  EventNames,
  EventPropertyKeys,
  EventPropertyValues,
  UserPropertyKeys,
} from '@companycam/companycam-events';
import Steven from 'helpers/steven';

function init() {
  // Set CCEvents on the window so it can be accessed in .erb files
  window.CCEvents = {
    logEvent: CCEvents.logEvent,
    EventNames,
    EventPropertyKeys,
    EventPropertyValues,
    UserPropertyKeys,
  };

  // Listen for page load to log view screen events
  function ccEventsOnPageLoad(event) {
    const pathname = new URL(event.detail.url).pathname;
    // In order to not create another listener for this same event, we are piggybacking steven off of this
    Steven.pageView({ pathname, url: event.detail.url });

    CCEvents.logEvent(EventNames.VIEW_SCREEN, {
      [EventPropertyKeys.SCREEN]: pathname,
    });
  }

  document.addEventListener('turbo:load', ccEventsOnPageLoad);
}

export default init;
